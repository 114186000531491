import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/nulogy.design/nulogy.design/src/components/MarkdownRenderer.js";
import { DropdownMenu, DropdownLink, DropdownButton, Button, Box, DropdownItem } from "@nulogy/components";
import { config } from "../../playgrounds/dropdown-menu";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const Example = makeShortcode("Example");
const PropsTable = makeShortcode("PropsTable");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`DropdownMenu`}</h1>
    <p>{`Dropdown Menus are used to collapse a group of associated actions together.`}</p>
    <Playground {...config} mdxType="Playground" />
    <h2>{`Variations`}</h2>
    <h3>{`Custom Trigger`}</h3>
    <p>{`A custom trigger element can be passed to change the trigger that opens the dropdowns.`}</p>
    <Example componentName="dropdownMenu" scope={{
      DropdownMenu,
      DropdownLink,
      DropdownButton,
      Button
    }} example="CustomTrigger" mdxType="Example" />
    <h3>{`Custom Colors`}</h3>
    <p>{`A custom trigger element can be passed to change the trigger that opens the dropdowns.`}</p>
    <Example componentName="dropdownMenu" scope={{
      DropdownMenu,
      DropdownLink,
      DropdownButton,
      Box
    }} example="CustomColours" mdxType="Example" />
    <h3>{`DropdownItem`}</h3>
    <p>{`Using the Dropdown Link and DropdownButton components with the DropdownMenu are recommended as they provide the correct link and button styles for the dropdown. However, wrapping another component with DropdownItem will also apply correct styling for the DropdownMenu.`}</p>
    <Example componentName="dropdownMenu" scope={{
      DropdownMenu,
      DropdownLink,
      DropdownButton,
      DropdownItem
    }} example="DropdownLink" showEditor mdxType="Example" />
    <h3>{`As a Controlled Component`}</h3>
    <p>{`The DropdownMenu is currently to be used as an uncontrolled component. However, the component does come with hooks to use to close the menu when elements within the menu are clicked. Use the closeMenu function provided by the DropdownMenu component via the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/render-props.html"
      }}>{`render props`}</a>{` React pattern as shown below. Note: event must be passed in to closeMenu.`}</p>
    <Example componentName="dropdownMenu" scope={{
      DropdownMenu,
      DropdownButton
    }} example="Controlled" showEditor mdxType="Example" />
    <h2>{`Props`}</h2>
    <PropsTable propsConfig={config.props} mdxType="PropsTable" />
    <h2>{`Related Components`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/iconic-button"
        }}>{`IconicButton`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      